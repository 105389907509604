import { Dispatch, SetStateAction } from 'react'
import CreditContainer from './Credit/CreditContainer'
import styles from './userInfo.module.scss'

interface UserInfoProps {
    userName: string
    isBaseInterface: boolean
    setIsOpenUserForm: Dispatch<SetStateAction<boolean>>
    userHasManagementPermission: boolean
}

const UserInfo = ({ userName, isBaseInterface, setIsOpenUserForm, userHasManagementPermission }: UserInfoProps) => (
    <div className={`${styles.userInfo} ${!isBaseInterface ? styles.demoUserInterface : ''}`}>
        {userHasManagementPermission ? (
            <div className={styles.userName} onClick={() => setIsOpenUserForm(true)}>
                {userName}
            </div>
        ) : (
            <div className={styles.userName_withoutUserManagementPermission}>{userName}</div>
        )}
        {isBaseInterface && <CreditContainer />}
    </div>
)

export default UserInfo
