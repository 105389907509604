import {
    ChangeUserAPIParams,
    CreateNewUserAPIParams,
    UserGameSettingsResponse,
    UsersResponseData,
} from 'types/adminService'
import { Balance } from 'types/user'
import $api from 'utils/http'
import { hashString } from '../utils/cryptoUtils'

export default class AdministrationUserService {
    static async getUsers(): Promise<UsersResponseData> {
        return $api.get(`${process.env.REACT_APP_API_URL}admin/user-managment/users`)
    }

    static async getBalanceByUserId(id: number) {
        return $api.get(`${process.env.REACT_APP_API_URL}admin/user-managment/users/balance`, {
            params: {
                id,
            },
        })
    }

    static async setRequestedUserBalance({ id, balance }: { id: number; balance: Balance[] }) {
        return $api.put(`${process.env.REACT_APP_API_URL}admin/user-managment/users/${id}/balance`, {
            balance,
        })
    }

    static async createNewUser(data: CreateNewUserAPIParams) {
        data.password = hashString(data.password)
        return $api.post(`${process.env.REACT_APP_API_URL}admin/user-managment/users`, data)
    }

    static async changeUserInfo({ id, data }: { id: number; data: ChangeUserAPIParams }) {
        if (data.password) {
            data.password = hashString(data.password)
        }

        return $api.put(`${process.env.REACT_APP_API_URL}admin/user-managment/users/${id}`, data)
    }

    static async removeUser(id: number) {
        return $api.delete(`${process.env.REACT_APP_API_URL}admin/user-managment/users/${id}`)
    }

    static async setUserGameSettings({
        id,
        settings,
    }: {
        id: number
        settings: string
    }): Promise<UserGameSettingsResponse> {
        return $api.put(`${process.env.REACT_APP_API_URL}admin/user-managment/users/${id}/settings/interface/base`, {
            settings: settings,
        })
    }

    static async getUserGameSettings(id: number): Promise<UserGameSettingsResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}admin/user-managment/users/${id}/settings/interface/base`)
    }
}
