import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch } from '@mui/material'
import styles from './userGameSettingsForm.module.scss'
import { User } from 'types/user'
import { showSnackbar } from 'store/slices/snackbarSlice'
import { useDispatch } from 'react-redux'
import { UserGameSettings } from 'types/adminService'
import AdministrationUserService from 'services/AdministrationUserService'
import React, { useEffect, useState, useCallback } from 'react'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import GameService from 'services/GameService'
import { parseCurrencyConfig } from 'utils/currencyUtils'
import { ConfiguredCurrency } from 'types/configuredCurrency'
import { SERVICE } from 'consts/constants'
import { EnvLanguage } from 'types/gameService'
import { IntegrationBackend } from 'types/common'
import BackendService from 'services/BackendService'
import { LicensedSite } from 'types/backendService'
import {
    ENVIRONMENT_SELECT_OPTIONS,
    GAME_MODE_SELECT_OPTIONS,
    INTEGRATION_BACKEND_SELECT_OPTIONS,
    SERVICE_SELECT_OPTIONS,
} from 'consts/selectOptions'
import { createCurrencyOptions, createLanguageOptions, createLicenseOptions } from 'utils/selectOptionsUtils'

interface GameLaunchSettingsFormProps {
    user: User
    userGameSettings: UserGameSettings
    isOpenGameLaunchSettings: boolean
    setIsOpenUserGameSettingsForm: React.Dispatch<React.SetStateAction<boolean>>
}

const UserGameSettingsForm: React.FC<GameLaunchSettingsFormProps> = ({
    user,
    userGameSettings,
    isOpenGameLaunchSettings,
    setIsOpenUserGameSettingsForm,
}) => {
    const dispatch = useDispatch()

    const [integrationBackend, setIntegrationBackend] = useState<string>(
        userGameSettings.integrationBackend ?? IntegrationBackend.Legacy
    )
    const [environment, setEnvironment] = useState<string>(userGameSettings.environment ?? '')
    const [currency, setCurrency] = useState<string>(userGameSettings.currency ?? '')
    const [mode, setMode] = useState<string>(userGameSettings.mode ?? '')
    const [language, setLanguage] = useState<string>(userGameSettings.language ?? '')
    const [service, setService] = useState<string>(userGameSettings.service ?? '')
    const [license, setLicense] = useState<string>(userGameSettings.license ?? '')
    const [isFullScreenMode, setIsFullScreenMode] = useState<boolean>(userGameSettings.isFullScreenMode === true)

    const [currencies, setCurrencies] = useState<ConfiguredCurrency[]>([])
    const [languages, setLanguages] = useState<EnvLanguage[]>([])
    const [sites, setSites] = useState<LicensedSite[]>([])

    const fetchCurrencies = useCallback(async () => {
        try {
            const {
                data: { currencies },
            } = await GameService.getConfiguredCurrencies(environment)
            setCurrencies(currencies.map(parseCurrencyConfig))
        } catch (error) {
            const errorMessage = (error as Error).message
            dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
            console.error(errorMessage)
        }
    }, [dispatch, environment])

    const fetchLanguages = useCallback(async () => {
        try {
            const {
                data: { languages },
            } = await GameService.getLanguagesInfo(environment)
            setLanguages(languages)
        } catch (error) {
            const errorMessage = (error as Error).message
            dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
            console.error(errorMessage)
        }
    }, [dispatch, environment])

    const isValidIntegrationBackend = (value: string): value is IntegrationBackend => {
        return Object.values(IntegrationBackend).includes(value as IntegrationBackend)
    }

    const fetchSites = useCallback(async () => {
        if (isValidIntegrationBackend(integrationBackend)) {
            try {
                const response = await BackendService.getLicensedSiteList(environment, integrationBackend)
                const { sites } = response.data
                setSites(sites)
            } catch (error) {
                const errorMessage = (error as Error).message
                dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
                console.error(errorMessage)
            }
        }
    }, [dispatch, environment, integrationBackend])

    useEffect(() => {
        if (environment) {
            fetchCurrencies()
        }
    }, [environment, fetchCurrencies])

    useEffect(() => {
        if (environment) {
            fetchLanguages()
        }
    }, [environment, fetchLanguages])

    useEffect(() => {
        if (environment) {
            fetchSites()
        }
    }, [environment, fetchSites])

    const handleClose = () => {
        setIsOpenUserGameSettingsForm(false)
    }

    const updateServiceAndIntegrationBackend = (service: string) => {
        setService(service)
        if (service === SERVICE.DMS) {
            setIntegrationBackend(IntegrationBackend.Legacy)
        }
    }

    const handleApply = async () => {
        try {
            const updatedData: UserGameSettings = {
                ...userGameSettings,
                environment,
                currency,
                mode,
                service,
                integrationBackend,
                language,
                license,
                isFullScreenMode,
            }
            const settings = JSON.stringify(updatedData)
            await AdministrationUserService.setUserGameSettings({ id: user.id, settings })
            dispatch(
                showSnackbar({
                    message: 'Game settings applied successfully',
                    severity: 'success',
                })
            )
            handleClose()
        } catch (error) {
            const errorMessage = (error as Error).message
            dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
            console.error(errorMessage)
        }
    }

    return (
        <Dialog open={isOpenGameLaunchSettings} onClose={handleClose}>
            <DialogTitle>
                <div className={styles.title}>
                    <span>Game Settings</span>
                    <span>{user.nickname}</span>
                </div>
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: '5px' }}>
                <div className={styles.form}>
                    <CustomSelect
                        className="userGameSettingsSelect"
                        label="Environment"
                        value={environment}
                        options={ENVIRONMENT_SELECT_OPTIONS}
                        onChange={(event) => setEnvironment(event.target.value)}
                    />
                    {currencies.length > 0 && (
                        <CustomSelect
                            className="userGameSettingsSelect"
                            label="Currency"
                            value={currency}
                            options={createCurrencyOptions(currencies)}
                            onChange={(event) => setCurrency(event.target.value)}
                        />
                    )}
                    <CustomSelect
                        className="userGameSettingsSelect"
                        label="Service"
                        value={service}
                        options={SERVICE_SELECT_OPTIONS}
                        onChange={(event) => updateServiceAndIntegrationBackend(event.target.value)}
                    />
                    <CustomSelect
                        className="userGameSettingsSelect"
                        label="Integration backend"
                        value={service === SERVICE.DMS ? IntegrationBackend.Legacy : integrationBackend}
                        options={INTEGRATION_BACKEND_SELECT_OPTIONS}
                        onChange={(event) => setIntegrationBackend(event.target.value as IntegrationBackend)}
                        disabled={service === SERVICE.DMS}
                    />
                    <CustomSelect
                        className="userGameSettingsSelect"
                        label="License"
                        value={license}
                        options={createLicenseOptions(sites)}
                        onChange={(event) => setLicense(event.target.value)}
                        disabled={service === SERVICE.DMS}
                    />
                    <CustomSelect
                        className="userGameSettingsSelect"
                        label="Mode"
                        value={mode}
                        options={GAME_MODE_SELECT_OPTIONS}
                        onChange={(event) => setMode(event.target.value)}
                    />
                    <CustomSelect
                        className="userGameSettingsSelect"
                        label="Language"
                        value={language}
                        options={createLanguageOptions(languages)}
                        onChange={(event) => setLanguage(event.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isFullScreenMode}
                                onChange={(event) => setIsFullScreenMode(event.target.checked)}
                            />
                        }
                        label="Full Screen Mode"
                        labelPlacement="start"
                    />
                </div>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', paddingBottom: '15px' }}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserGameSettingsForm
