import { Game, RunGameParameters } from 'types/gameService'
import { useCallback } from 'react'
import { useAppSelector } from 'hooks/useRedux'
import GameCard from '../GameCard/GameCard'

interface GameCardDemoModeContainerProps {
    game: Game
    imagePath: string | undefined
    runGame: (gameParams: RunGameParameters) => void
}

const GameCardDemoModeContainer: React.FC<GameCardDemoModeContainerProps> = ({ game, imagePath, runGame }) => {
    const { gameSettings } = useAppSelector((state) => state.user)
    const { gameUID: gameUid = '', gameName, latestVersion, release } = game

    const runDemoUserGame = useCallback(() => {
        if (gameSettings) {
            const { mode, language, currency } = gameSettings

            runGame({
                mode,
                gameUid,
                version: latestVersion || release?.tag || '',
                language,
                currency,
            })
        }
    }, [gameSettings, gameUid, latestVersion, release?.tag, runGame])

    return <GameCard gameName={gameName} imagePath={imagePath} runGame={runDemoUserGame} />
}

export default GameCardDemoModeContainer
