import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'

import userAuthReducer from './slices/userAuthSlice'
import userDataReducer from './slices/userDataSlice'
import usersReducer from './slices/usersSlice'
import currencyReducer from './slices/currencySlice'
import currentPageReducer from './slices/currentPageSlice'
import currentGameReducer from './slices/currentGameSlice'
import configUIReducer from './slices/configUISlice'
import adminPanelReducer from './slices/adminPanelSlice'
import gamesReducer from './slices/gamesSlice'
import gameLaunchSettingsReducer from './slices/gameLaunchSettingsSlice'
import rolesReducer from './slices/rolesSlice'
import permissionsReducer from './slices/permissionsSlice'
import allGamesInfoSlice from './slices/allGamesInfoSlice'
import tagsSlice from './slices/tagsSlice'
import workingGamesFilterReducer from './slices/workingGamesFilterSlice'
import snackbarReducer from './slices/snackbarSlice'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'currencyState', 'stateUI', 'environmentPageUI', 'gameLaunchSettings'],
    stateReconciler: autoMergeLevel2,
}

const reducers = {
    user: userDataReducer,
    userAuth: userAuthReducer,
    users: usersReducer,
    currentGame: currentGameReducer,
    games: gamesReducer,
    currencyState: currencyReducer,
    currentPage: currentPageReducer,
    stateUI: configUIReducer,
    adminPanel: adminPanelReducer,
    gameLaunchSettings: gameLaunchSettingsReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    allGamesInfo: allGamesInfoSlice,
    tags: tagsSlice,
    workingGamesFilter: workingGamesFilterReducer,
    snackbar: snackbarReducer,
}

const mainReducer = combineReducers(reducers)

const persistedReducer = persistReducer<ReturnType<typeof mainReducer>>(persistConfig, mainReducer)

export default persistedReducer
