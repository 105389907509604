import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import UserService from 'services/UserService'
import { BalanceMode } from 'types/common'
import { Balance, User } from 'types/user'
import { ValidatedUserGameSettings, validateUserGameSettings } from 'utils/validation'
import { logout } from './userAuthSlice'
import { applyUserSettings } from './configUISlice'
import { showSnackbar } from './snackbarSlice'

interface CurrentBalanceData {
    real: Balance
    fun: Balance
}

interface UserState {
    info: User | undefined
    currentBalanceData: CurrentBalanceData
    allowedEnvironments: string[]
    gameSettings?: ValidatedUserGameSettings
}

const initialState: UserState = {
    info: undefined,
    currentBalanceData: {
        real: { mode: BalanceMode.REAL, value: 0 },
        fun: { mode: BalanceMode.FUN, value: 0 },
    },
    allowedEnvironments: [],
}

export const fetchUserInfo = createAsyncThunk('userData/getUserInfo', async (_, { rejectWithValue, dispatch }) => {
    try {
        const response = await UserService.getUserInfo()
        const { user } = response.data
        return user
    } catch (error) {
        const errorMessage = (error as Error).message
        console.error(errorMessage)
        dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
        return rejectWithValue(errorMessage)
    }
})

export const fetchUserBalance = createAsyncThunk(
    'userData/getUserBalance',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const response = await UserService.getBalanceAuthenticatedUser()
            const { balance } = response.data
            dispatch(updateBalance(balance))
            return balance
        } catch (error) {
            return rejectWithValue((error as Error).message)
        }
    }
)

export const fetchAndApplyUserGameSettings = createAsyncThunk(
    'userData/fetchUserGameSettings',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const response = await UserService.getGameSettings()
            const { settings } = response.data
            const parsedData = JSON.parse(settings)
            const validationResult = validateUserGameSettings(parsedData)

            if (validationResult.success) {
                dispatch(applyUserSettings(validationResult.data))
                return validationResult.data
            } else {
                dispatch(logout())
                dispatch(resetUserInfo())
                const { path, message } = validationResult.error.errors[0]
                return rejectWithValue(`${path} ${message}`)
            }
        } catch (error) {
            dispatch(logout())
            dispatch(resetUserInfo())
            return rejectWithValue((error as Error).message)
        }
    }
)

const userDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<User>) => {
            state.info = action.payload
            updateBalanceData(state, action.payload.balance)
        },
        updateBalance: (state, action: PayloadAction<Balance[]>) => {
            updateBalanceData(state, action.payload)
        },
        setAllowedEnvironments: (state, action: PayloadAction<string[]>) => {
            state.allowedEnvironments = action.payload
        },

        resetUserInfo: (state) => {
            state.gameSettings = undefined
            state.info = undefined
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(
                fetchAndApplyUserGameSettings.fulfilled,
                (state, action: PayloadAction<ValidatedUserGameSettings>) => {
                    state.gameSettings = action.payload
                }
            )
            .addCase(fetchAndApplyUserGameSettings.rejected, (state, action) => {
                console.error(action.payload)
                alert(
                    'There seems to be an issue with your account settings. Please reach out to the administrator who configured them for assistance.'
                )
            })
            .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<User>) => {
                state.info = action.payload
            })
    },
})

export const { setUserData, updateBalance, setAllowedEnvironments, resetUserInfo } = userDataSlice.actions

export default userDataSlice.reducer

function updateBalanceData(state: UserState, balance: Balance[]): void {
    const creditReal = balance.find((balance) => balance.mode === BalanceMode.REAL)?.value || 0
    const creditFun = balance.find((balance) => balance.mode === BalanceMode.FUN)?.value || 0
    state.currentBalanceData.real.value = creditReal
    state.currentBalanceData.fun.value = creditFun
}
