import { z } from 'zod'
import { ENVIRONMENT_WITHOUT_LIVE, SERVICE } from '../consts/constants'
import { IntegrationBackend } from '../types/common'
import { GameMode } from '../types/gameService'

export const gameMetadataSchema = z.object({
    developer: z.string().optional(),
})

export const validateGameMetadata = (data: { developer: string }) => {
    return gameMetadataSchema.safeParse(data)
}

export const UserGameSettingsSchema = z.object({
    environment: z.string().refine((value) => Object.values(ENVIRONMENT_WITHOUT_LIVE).includes(value), {
        message: 'Environment must be one of the allowed environments',
    }),
    currency: z.string().min(1),
    integrationBackend: z.nativeEnum(IntegrationBackend),
    license: z.string(),
    service: z.string().refine((value) => Object.values(SERVICE).includes(value), {
        message: 'Service must be one of the allowed services',
    }),
    mode: z.nativeEnum(GameMode),
    language: z.string().min(1),
    isFullScreenMode: z.boolean(),
})

export const validateUserGameSettings = (data: { settings: string }) => {
    return UserGameSettingsSchema.safeParse(data)
}

export type ValidatedUserGameSettings = z.infer<typeof UserGameSettingsSchema>
