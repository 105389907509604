import { Grid } from '@mui/material'
import { GameContainer } from '../game/GameContainer'
import { Game, RunGameParameters } from 'types/gameService'

interface GamesProps {
    games: Game[]
    runGame: (gameParams: RunGameParameters) => void
}

export const Games: React.FC<GamesProps> = ({ games, runGame }) => {
    return (
        <Grid container sx={{ margin: 0 }}>
            {games.map((game) => {
                return <GameContainer key={game.gameUID + game.gameClass} game={game} runGame={runGame} />
            })}
        </Grid>
    )
}
