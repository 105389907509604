import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { logout } from 'store/slices/userAuthSlice'
import Header from './Header'

const HeaderContainer: React.FC = () => {
    const dispatch = useAppDispatch()

    const permissions = useAppSelector((state) => state.user.info?.role?.permissions)
    const isAuth = useAppSelector((state) => state.userAuth.isAuth)
    const currentPage = useAppSelector((state) => state.currentPage.currentPage)
    const { isBaseInterface } = useAppSelector((state) => state.stateUI)

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <Header
            isAuth={isAuth}
            handleLogout={handleLogout}
            permissions={permissions}
            currentPage={currentPage}
            isBaseInterface={isBaseInterface}
        />
    )
}

export default HeaderContainer
