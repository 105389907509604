import { Grid, SelectChangeEvent } from '@mui/material'
import { Game as GameInterface, RunGameParameters } from 'types/gameService'
import GameCardSimpleMode from './GameCardSimpleMode/GameCardSimpleMode'
import GameCardWithControlPanel from './gameCardWithControlPanel/GameCardWithControlPanel'
import GameCardDemoModeContainer from './GameCardDemoMode/GameCardDemoModeContainer'

interface GameProps {
    game: GameInterface
    runGame: (gameParams: RunGameParameters) => void
    service: string
    selectedLanguage: string
    handleChangeLanguage: (event: SelectChangeEvent<string>) => void
    selectedCurrency: string
    handleChangeCurrency: (event: SelectChangeEvent<string>) => void
    version: string
    handleSelectVersion: (version: string) => void
    imagePath: string | undefined
    handleResetGameState: (gameMode: string) => Promise<void>
    isSimpleLaunchGameMode: boolean
    isBaseInterface: boolean
}

export const Game: React.FC<GameProps> = ({
    game,
    runGame,
    service,
    selectedLanguage,
    handleChangeLanguage,
    selectedCurrency,
    handleChangeCurrency,
    version,
    handleSelectVersion,
    imagePath,
    handleResetGameState,
    isSimpleLaunchGameMode,
    isBaseInterface,
}) => {
    const renderBaseInterface = () => {
        if (isSimpleLaunchGameMode) {
            return (
                <GameCardSimpleMode
                    game={game}
                    imagePath={imagePath}
                    runGame={runGame}
                    selectedLanguage={selectedLanguage}
                />
            )
        }
        return (
            <GameCardWithControlPanel
                game={game}
                runGame={runGame}
                service={service}
                selectedLanguage={selectedLanguage}
                handleChangeLanguage={handleChangeLanguage}
                selectedCurrency={selectedCurrency}
                handleChangeCurrency={handleChangeCurrency}
                version={version}
                handleSelectVersion={handleSelectVersion}
                handleResetGameState={handleResetGameState}
                imagePath={imagePath}
            />
        )
    }

    return (
        <Grid item xs={12} sm={6} lg={4} sx={{ display: 'flex', justifyContent: 'center' }}>
            {isBaseInterface ? (
                renderBaseInterface()
            ) : (
                <GameCardDemoModeContainer game={game} imagePath={imagePath} runGame={runGame} />
            )}
        </Grid>
    )
}
