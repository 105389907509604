import UserInfo from './UserInfo'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import UserForm from 'components/UserForm/UserForm'
import { useState } from 'react'
import { UserFormType } from 'types/common'
import { fetchUserInfo } from 'store/slices/userDataSlice'
import { userHasPermission } from 'utils/userUtils'
import { PermissionTitle } from 'types/user'

const UserInfoContainer = () => {
    const user = useAppSelector((state) => state.user.info)
    const { isBaseInterface } = useAppSelector((state) => state.stateUI)

    const [isOpenUserForm, setIsOpenUserForm] = useState(false)

    const dispatch = useAppDispatch()

    const handleSuccessCallback = () => {
        dispatch(fetchUserInfo())
    }

    if (!user) return null

    const { permissions, title } = user.role

    const userHasManagementPermission = permissions
        ? userHasPermission(permissions, PermissionTitle.UserManagement)
        : false

    const isAdmin = title === 'admin'

    return (
        <>
            <UserInfo
                userName={user.nickname}
                isBaseInterface={isBaseInterface}
                setIsOpenUserForm={setIsOpenUserForm}
                userHasManagementPermission={userHasManagementPermission}
            />
            <UserForm
                user={user}
                isOpenUserForm={isOpenUserForm}
                setIsOpenUserForm={setIsOpenUserForm}
                userFormType={UserFormType.ChangeUser}
                titleUserForm="Edit user"
                isAdmin={isAdmin}
                onSuccessCallback={handleSuccessCallback}
            />
        </>
    )
}

export default UserInfoContainer
