export const ROLE = {
    ADMIN: 'admin',
    DEVELOPER: 'developer',
    CERTIFICATOR: 'certificator',
}

export const ENVIRONMENT = {
    DEV: 'DEV',
    STAGE: 'STAGE',
    CERT: 'CERT',
    LIVE: 'LIVE',
}

export const ENVIRONMENT_WITHOUT_LIVE = Object.fromEntries(
    Object.entries(ENVIRONMENT).filter(([key]) => key !== 'LIVE')
)

export const LANGUAGE = {
    EN: 'EN',
    IT: 'IT',
}

export const SERVICE = {
    DMS: 'DMS',
    LCS: 'LCS',
}

export const INTEGRATION = {
    SEAMLESS: 'SEAMLESS',
    WALLETTRANSFER: 'WALLETTRANSFER',
}

export const EXIT_URL = 'https:wallet.octaviangames.com'

export const ROUTE = {
    MAIN: '/',
    LOGIN: '/login',
    ADMIN: '/admin',
    ENVIRONMENTS: '/environments',
    LIVE_ENV_MONITORING: '/liveEnvMonitoring',
}

export const GAME_MODE = ['DEMO', 'REAL', 'FUN']

export const DEFAULT_SELECTED_LICENSED_SITE = {
    NAME: 'LIGHT_WALLET_2_SITE',
    LICENSE_DESCRIPTION: 'OGS light wallet v2',
}
