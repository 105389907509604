import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { styled } from '@mui/material/styles'

interface CustomButtonProps extends Partial<Omit<ButtonProps, 'onClick' | 'children'>> {
    label: string
    onClick: () => void
}

const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    },
}))

export const CustomButton = ({ label, onClick, ...props }: CustomButtonProps) => {
    return (
        <StyledButton onClick={onClick} variant="contained" size="small" {...props}>
            {label}
        </StyledButton>
    )
}
