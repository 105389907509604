import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export const Preloader: React.FC = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 999,
            }}
        >
            <CircularProgress />
        </Box>
    )
}
