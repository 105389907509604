import { styled, TableCell, tableCellClasses } from '@mui/material'
import TableRow from '@mui/material/TableRow'
import { Tag } from 'types/adminService'
import styles from './tagsTableRow.module.scss'
import { CustomButton } from 'components/CustomButton'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

interface TagsTableRowProps {
    tag: Tag
    handleFormOpen: () => void
    removeTag: () => void
}

const TagsTableRow: React.FC<TagsTableRowProps> = ({ tag, handleFormOpen, removeTag }) => {
    return (
        <StyledTableRow>
            <StyledTableCell align="center" component="th" scope="row">
                {tag.id}
            </StyledTableCell>
            <StyledTableCell align="center">{tag.title}</StyledTableCell>
            <StyledTableCell align="center">{tag.description}</StyledTableCell>
            <StyledTableCell align="center">{tag.value}</StyledTableCell>
            <StyledTableCell align="center">
                <div className={styles.btnGroup}>
                    <CustomButton onClick={handleFormOpen} label="Edit" />
                    <CustomButton onClick={removeTag} label="Remove" />
                </div>
            </StyledTableCell>
        </StyledTableRow>
    )
}

export default TagsTableRow
