import { Card, CardMedia, type SelectChangeEvent } from '@mui/material'
import { Game, RunGameParameters } from 'types/gameService'
import GameControlPanel from './gameControlPanel/GameControlPanel'
import styles from './gameCardWithControlPanel.module.scss'

interface GameCardWithControlPanelProps {
    game: Game
    handleChangeCurrency: (event: SelectChangeEvent<string>) => void
    handleChangeLanguage: (event: SelectChangeEvent<string>) => void
    handleSelectVersion: (version: string) => void
    handleResetGameState: (gameMode: string) => Promise<void>
    imagePath: string | undefined
    runGame: (gameParams: RunGameParameters) => void
    selectedCurrency: string
    selectedLanguage: string
    service: string
    version: string
}

const GameCardWithControlPanel = ({
    game,
    runGame,
    service,
    version,
    imagePath,
    handleSelectVersion,
    selectedLanguage,
    handleChangeLanguage,
    selectedCurrency,
    handleChangeCurrency,
    handleResetGameState,
}: GameCardWithControlPanelProps) => {
    return (
        <Card className={styles.card}>
            <CardMedia
                component="img"
                alt={`logo ${game.gameName}`}
                sx={{ height: '250px' }}
                image={imagePath}
                loading="lazy"
            />
            <GameControlPanel
                game={game}
                runGame={runGame}
                service={service}
                version={version}
                handleSelectVersion={handleSelectVersion}
                selectedLanguage={selectedLanguage}
                handleChangeLanguage={handleChangeLanguage}
                selectedCurrency={selectedCurrency}
                handleChangeCurrency={handleChangeCurrency}
                imagePath={imagePath}
                handleResetGameState={handleResetGameState}
            />
        </Card>
    )
}

export default GameCardWithControlPanel
