import { Card, CardMedia } from '@mui/material'
import styles from './gameCard.module.scss'

interface GameCardProps {
    gameName?: string
    imagePath?: string
    runGame: () => void
}

const GameCard = ({ gameName, imagePath, runGame }: GameCardProps) => {
    return (
        <Card className={styles.imageWrapper} onClick={runGame}>
            <CardMedia>
                <CardMedia sx={{ objectFit: 'fill' }} component="img" alt={gameName} image={imagePath} loading="lazy" />
            </CardMedia>
        </Card>
    )
}

export default GameCard
