import { useEffect, useState } from 'react'
import { User } from 'types/user'
import UserGameSettingsForm from './gameLaunchSettingsForm'
import AdministrationUserService from 'services/AdministrationUserService'
import { showSnackbar } from 'store/slices/snackbarSlice'
import { useDispatch } from 'react-redux'
import { UserGameSettings } from 'types/adminService'
import { Preloader } from 'components/Preloader/Preloader'

interface GameLaunchSettingsContainerProps {
    user: User
    isOpenUserGameSettingsForm: boolean
    setIsOpenUserGameSettingsForm: React.Dispatch<React.SetStateAction<boolean>>
}

const UserGameSettingsContainer: React.FC<GameLaunchSettingsContainerProps> = ({
    user,
    isOpenUserGameSettingsForm,
    setIsOpenUserGameSettingsForm,
}) => {
    const [userGameSettings, setUserGameSettings] = useState<UserGameSettings | null>(null)
    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await AdministrationUserService.getUserGameSettings(user.id)

                let parsedSettings = {}

                if (data.settings) {
                    try {
                        parsedSettings = JSON.parse(data.settings)
                    } catch (parseError) {
                        dispatch(showSnackbar({ message: 'Invalid game settings format', severity: 'error' }))
                        console.error('Failed to parse Game Settings:', parseError)
                    }
                }

                setUserGameSettings(parsedSettings)
            } catch (error) {
                const errorMessage = (error as Error).message
                dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
                console.error(errorMessage)
            } finally {
                setLoading(false)
            }
        }

        fetchData()
    }, [dispatch, user.id])

    return (
        <>
            {loading ? (
                <Preloader />
            ) : (
                <UserGameSettingsForm
                    user={user}
                    userGameSettings={userGameSettings || {}}
                    isOpenGameLaunchSettings={isOpenUserGameSettingsForm}
                    setIsOpenUserGameSettingsForm={setIsOpenUserGameSettingsForm}
                />
            )}
        </>
    )
}

export default UserGameSettingsContainer
