import $api from 'utils/http'
import { Balance } from 'types/user'
import { DataBalanceResponse, MissettledTransactionFeatureStateResponse, UserInfoResponse } from '../types/userService'
import { UserGameSettingsResponse } from '../types/adminService'

export default class UserService {
    static async getBalanceAuthenticatedUser(): Promise<DataBalanceResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}user/balance`)
    }

    static async setBalanceAuthenticatedUser({ balance }: { balance: Balance[] }): Promise<DataBalanceResponse> {
        return $api.post(`${process.env.REACT_APP_API_URL}user/balance`, { balance })
    }

    static async getMissettledTransactionFeatureState(): Promise<MissettledTransactionFeatureStateResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}user/test/missettledtr`)
    }

    static async setMissettledTransactionFeatureState(
        missettledTransactionTestRequest: boolean
    ): Promise<MissettledTransactionFeatureStateResponse> {
        return $api.post(`${process.env.REACT_APP_API_URL}user/test/missettledtr`, { missettledTransactionTestRequest })
    }

    static async getGameSettings(): Promise<UserGameSettingsResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}user/settings/interface/base`)
    }

    static async getUserInfo(): Promise<UserInfoResponse> {
        return $api.get(`${process.env.REACT_APP_API_URL}user/info`)
    }
}
