import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { fetchUsers } from 'store/slices/usersSlice'
import { showSnackbar } from 'store/slices/snackbarSlice'
import UsersTable from 'components/pages/adminPage/editUsers/UsersTable/UsersTable'
import UserForm from 'components/UserForm/UserForm'
import CreditForm from 'components/CreditForm'
import { UserRemoveDialog } from 'components/pages/adminPage/editUsers/UserRemoveDialog'
import { User } from 'types/user'
import PasswordResetForm from 'components/pages/adminPage/editUsers/PasswordResetForm'
import { UserFormType } from 'types/common'
import AdministrationUserService from 'services/AdministrationUserService'
import { Preloader } from 'components/Preloader/Preloader'
import UserGameSettingsContainer from '../UserGameSettings/UserGameSettingsContainer'

export const EditUsersContainer = () => {
    const dispatch = useAppDispatch()

    const { users, isLoading: isLoadingUsers } = useAppSelector((state) => state.users)
    // Can't reach this code without user info
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const currentUser = useAppSelector((state) => state.user.info!)
    const role = useAppSelector((state) => state.user.info?.role.title)
    const { currentCurrency, funCurrencyConfig } = useAppSelector((state) => state.currencyState)
    const { roles, isLoading: isLoadingRoles } = useAppSelector((state) => state.roles)

    const [isOpenUserForm, setIsOpenUserForm] = useState<boolean>(false)
    const [isOpenUserGameSettingsForm, setIsOpenUserGameSettingsForm] = useState<boolean>(false)
    const [isOpenUserRemoveDialog, setIsOpenUserRemoveDialog] = useState<boolean>(false)
    const [isOpenCreditForm, setIsOpenCreditForm] = useState<boolean>(false)
    const [isOpenPasswordForm, setIsOpenPasswordForm] = useState<boolean>(false)
    const [titleUserForm, setTitleUserForm] = useState<string>('')
    const [userFormType, setUserFormType] = useState<UserFormType>(UserFormType.AddUser)
    const [creditFormBalanceFun, setCreditFormBalanceFun] = useState<number>(0)
    const [creditFormBalanceReal, setCreditFormBalanceReal] = useState<number>(0)
    const [userId, setUserId] = useState<number>()
    const [selectedUser, setSelectedUser] = useState<User>({} as User)

    const isAdmin = role === 'admin'

    useEffect(() => {
        dispatch(fetchUsers())
    }, [dispatch])

    const handleClickRemoveUser = (user: User) => {
        setIsOpenUserRemoveDialog(true)
        setSelectedUser(user)
    }

    const removeUser = async (id: number) => {
        setIsOpenUserRemoveDialog(false)
        try {
            await AdministrationUserService.removeUser(id)
            dispatch(fetchUsers())
            dispatch(showSnackbar({ message: 'User successfully removed', severity: 'success' }))
        } catch (error) {
            const errorMessage = (error as Error).message
            dispatch(showSnackbar({ message: errorMessage, severity: 'error' }))
            console.error(errorMessage)
        }
    }

    const changeUser = async (nickname: string) => {
        const userToEdit = isAdmin ? users.find((user) => user.nickname === nickname) || currentUser : currentUser
        setSelectedUser(userToEdit)
        setTitleUserForm('Edit user')
        setUserFormType(UserFormType.ChangeUser)
        setIsOpenUserForm(true)
    }

    const changePassword = async (id: number) => {
        const userToEdit = isAdmin ? users.find((user) => user.id === id) || currentUser : currentUser
        setSelectedUser(userToEdit)
        setIsOpenPasswordForm(true)
    }

    const openUserForm = () => {
        setSelectedUser({} as User)
        setTitleUserForm('Add new user')
        setUserFormType(UserFormType.AddUser)
        setIsOpenUserForm(true)
    }

    const handleOpenCreditForm = async ({
        realBalance,
        funBalance,
        userId,
    }: {
        realBalance: number
        funBalance: number
        userId: number
    }) => {
        setIsOpenCreditForm(true)
        setCreditFormBalanceReal(realBalance)
        setCreditFormBalanceFun(funBalance)
        setUserId(userId)
    }

    const handleOpenGameLaunchSettings = (user: User) => {
        setIsOpenUserGameSettingsForm(true)
        setSelectedUser(user)
    }

    const handleSuccessCallback = () => {
        dispatch(fetchUsers())
    }

    return isLoadingUsers && isLoadingRoles ? (
        <Preloader />
    ) : (
        <>
            {isAdmin ? (
                <UsersTable
                    users={users}
                    handleOpenGameLaunchSettings={handleOpenGameLaunchSettings}
                    handleOpenCreditForm={handleOpenCreditForm}
                    handleClickRemoveUser={handleClickRemoveUser}
                    changeUser={changeUser}
                    changePassword={changePassword}
                    currentCurrency={currentCurrency}
                    funCurrencyConfig={funCurrencyConfig}
                    openUserForm={openUserForm}
                    isAdmin={isAdmin}
                ></UsersTable>
            ) : (
                <UsersTable
                    users={[currentUser]}
                    handleOpenGameLaunchSettings={handleOpenGameLaunchSettings}
                    handleOpenCreditForm={handleOpenCreditForm}
                    handleClickRemoveUser={handleClickRemoveUser}
                    changeUser={changeUser}
                    changePassword={changePassword}
                    currentCurrency={currentCurrency}
                    funCurrencyConfig={funCurrencyConfig}
                    openUserForm={openUserForm}
                    isAdmin={isAdmin}
                ></UsersTable>
            )}
            {currentCurrency && funCurrencyConfig && (
                <CreditForm
                    userAPIType="RequestedUser"
                    isOpenCreditForm={isOpenCreditForm}
                    setIsOpenCreditForm={setIsOpenCreditForm}
                    realBalance={creditFormBalanceReal}
                    funBalance={creditFormBalanceFun}
                    userId={userId}
                    realCurrency={currentCurrency}
                    funCurrencyConfig={funCurrencyConfig}
                    asyncAction={() => dispatch(fetchUsers())}
                ></CreditForm>
            )}
            <UserForm
                user={selectedUser}
                isAdmin={isAdmin}
                userFormType={userFormType}
                titleUserForm={titleUserForm}
                isOpenUserForm={isOpenUserForm}
                setIsOpenUserForm={setIsOpenUserForm}
                roles={roles}
                onSuccessCallback={handleSuccessCallback}
            ></UserForm>
            <PasswordResetForm
                user={selectedUser}
                isOpenPasswordForm={isOpenPasswordForm}
                setIsOpenPasswordForm={setIsOpenPasswordForm}
            ></PasswordResetForm>
            <UserRemoveDialog
                selectedUser={selectedUser}
                isOpenUserRemoveDialog={isOpenUserRemoveDialog}
                setIsOpenUserRemoveDialog={setIsOpenUserRemoveDialog}
                removeUser={removeUser}
            ></UserRemoveDialog>
            {isOpenUserGameSettingsForm && (
                <UserGameSettingsContainer
                    user={selectedUser}
                    isOpenUserGameSettingsForm={isOpenUserGameSettingsForm}
                    setIsOpenUserGameSettingsForm={setIsOpenUserGameSettingsForm}
                ></UserGameSettingsContainer>
            )}
        </>
    )
}
