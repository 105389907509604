import { ENVIRONMENT_WITHOUT_LIVE, GAME_MODE, SERVICE } from './constants'
import { CategorySort, IntegrationBackend } from 'types/common'

export const ENVIRONMENT_SELECT_OPTIONS = Object.values(ENVIRONMENT_WITHOUT_LIVE).map((env) => ({
    value: env,
    label: env,
}))

export const SERVICE_SELECT_OPTIONS = Object.values(SERVICE).map((service) => ({
    value: service,
    label: service,
}))

export const INTEGRATION_BACKEND_SELECT_OPTIONS = Object.values(IntegrationBackend).map((backend) => ({
    value: backend,
    label: backend,
}))

export const GAME_MODE_SELECT_OPTIONS = GAME_MODE.map((gameMode) => ({
    value: gameMode,
    label: gameMode,
}))

export const CATEGORY_SORT_SELECT_OPTIONS = Object.values(CategorySort).map((sortOption) => ({
    value: sortOption,
    label: sortOption,
}))
